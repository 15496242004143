'use client'
import { ModalContainer_4,Text } from "./styledModal"


const Modal_2 = ({onClose}) => {
    return (
        <ModalContainer_4 >
            
        <Text>Yes, BRC-20 tokens are fungible, which means they are interchangeable and have equal value.
</Text>
    

        </ModalContainer_4>
    )
}
export default Modal_2