const images = {
    Logo: '/images/HomeIcon/Logo.png',
    ButtonMarket:'/images/HomeIcon/buttonmain.png',
    ButtonConnect:'/images/HomeIcon/button main.png',
    Background:'/images/HomeIcon/bg-new.png',
    IconHome1:'/images/HomeIcon/tw.png',
    IconHome2: '/images/HomeIcon/telegram.png',
    IconHome3: '/images/HomeIcon/A2.png',
    IconHome4: '/images/HomeIcon/color 1.png',
    IconHome5: '/images/HomeIcon/Rectangle 4.png',
    IconHome6: '/images/HomeIcon/Rectangle 4-1.png',
    IconHome7: '/images/HomeIcon/Rectangle 4-2.png',
    IconHome8: '/images/HomeIcon/Rectangle 4-3.png',
    IconHome9: '/images/HomeIcon/114.png',
    IconHome10: '/images/HomeIcon/6.png',
    IconHome11: '/images/HomeIcon/7.png',
    IconHome12: '/images/HomeIcon/8.png',
    IconHome13: '/images/HomeIcon/9.png',
    IconHome14: '/images/HomeIcon/10.png',
    IconHome15: '/images/HomeIcon/112.png',
    IconHome16: '/images/HomeIcon/Frame 121212219.png',
    LogoMobile:'/images/HomeIcon/Logo-.png',
    IconHomePage1_a:'/images/HomeIcon/Home_1a.png',
    TextImg_1_Mobile:'/images/HomeIcon/TextImg_1_Mobile.png',
    IconHomePage1_b:'/images/HomeIcon/Buy$SBRConcryptoexchange.png',
    TextImg_2_Mobile:'/images/HomeIcon/Buy$SBRConcryptoexchange.png',
    IconHomePage2: '/images/HomeIcon/Rectangle 5.png',
    IconHomePage2_Title: '/images/HomeIcon/WhatisSBRC_.png',
    IconHomePage3: '/images/HomeIcon/coin 3 1.png',
    IconHomePage3_1: '/images/HomeIcon/Group121212207.png',
    IconHomePage3_2: '/images/HomeIcon/SBRC introduce.png',
    SBRCintroduce:'/images/HomeIcon/SBRCintroduce.png',
    SBRCsystem:'/images/HomeIcon/SBRCsystem.png',
    IconHomePage4_1a: '/images/HomeIcon/dot-question.png',
    IconHomePage4_1b: '/images/HomeIcon/click.png',
    IconHomePage4_2a: '/images/HomeIcon/akar-icons_chevron-right_2.png',
    IconHomePage4_2b: '/images/HomeIcon/akar-icons_chevron-right.png',
    FAQs: '/images/HomeIcon/FAQs.png',
    IconHomePage5_1: '/images/HomeIcon/footer-bg1.png',
    IconHomePage5_2: '/images/HomeIcon/mobile.png',
    IconHomePage5_3: '/images/HomeIcon/telegram(1)1.png',
    IconHomePage5_4: '/images/HomeIcon/twitter(3)1.png',
    IconHomePage5_5: '/images/HomeIcon/openmoji_regional-indicator-c.png',
    IconHomePage5_6: '/images/HomeIcon/15.png',
    IconCancel:'/images/HomeIcon/cancel.png',
    SatoshiNakamoto:'/images/HomeIcon/SatoshiNakamoto.png',
    Buy5:'/images/HomeIcon/Buy5.png'
}
export default images