import images from '../configs/images'
import {Container, Img, RowTop, Text, Title, Wraper} from './Component/styled/pageHome2'

const PageHome2: React.FC = () => {
  return (
    <Container
      style={{
        backgroundImage: `url("./images/HomeIcon/Section 2-min.png")`,
      }}
    >
      <Wraper>
        <Title>
          <img
            className="IconHomePage2_Title"
            src={images.IconHomePage2_Title}
            alt="IconHomePage2_Title"
          />
        </Title>
        <RowTop className="Text1">
          <Text>$SBRC is a memeable blend of SAT and BRC.</Text>
          <Text>SAT, short for Satoshis, while BRC refers to the BRC-20 tokens.</Text>
          <Text>By combining the two, weve created $SBRC</Text>
          <Text>
            One Satoshi is a fraction of one Bitcoin, and 100 million Satoshis make up 1 BTC.
          </Text>
        </RowTop>
      </Wraper>
    </Container>
  )
}
export default PageHome2
