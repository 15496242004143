import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  .Title {
    height: 32px;
  }
  @media (max-width: 575px) {
    justify-content: flex-start;
  }
`
export const Modal = styled.div`
  display: flex;
  justify-content: center;
`
export const Wraper = styled.div`
  display: flex;
  flex-direction: row;
  // gap: 30px;
  align-items: center;
  padding-right: 100px;
  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
    // padding: 0px 20px;
    width: 100%;
  }
`
export const Text = styled.div`
  font-size: 20px;
  color: white;
  width: 100%;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
export const Title = styled.div`
  height: 80px;
  width: 160px;
  display: flex;
  align-items: center;
  .FAQs {
    height: 100%;
    width: 100%;
  }
  @media (max-width: 575px) {
    font-size: 32px;
  }
`

export const Img = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  border-radius: 12px;
  gap: 30px;
  background: #252433;
  padding: 0px 15px;
  width: 100%;

  .IconNormal {
    height: 30px;
    width: 30px;
  }

  .IconHover {
    display: none;
  }
  .Hover {
    display: none;
  }
  @media (max-width: 575px) {
    width: 80%;
    gap: 20px;
  }
`
export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  left: 80px;
  width: 750px;
  gap: 15px;
  :active {
    background: linear-gradient(
      90deg,
      #e86e00 -1.64%,
      #f18900 3.29%,
      #f99f00 9.22%,
      #fdac00 14.15%,
      #ffb100 19.09%,
      #e86e00 97.07%
    );
  }
  @media (max-width: 575px) {
    width: 100%;
    left: 0%;
    align-items: center;

    .Icon_2 {
      position: absolute;
      top: 350px;
    }
    .Icon_3 {
      position: absolute;
      top: 470px;
    }
    .Icon_4 {
      position: absolute;
      top: 590px;
    }
  }

  :hover {
    background: linear-gradient(
      270deg,
      rgba(232, 187, 15, 0.2) -0.38%,
      rgba(217, 181, 60, 0.2) 100%
    );

    @media (max-width: 575px) {
      box-shadow: 0px 0px 5px 6px #ffffff47;
      background: linear-gradient(
        90deg,
        #e86e00 -1.64%,
        #f18900 3.29%,
        #f99f00 9.22%,
        #fdac00 14.15%,
        #ffb100 19.09%,
        #e86e00 97.07%
      );
    }
    .text {
      color: black;
      background: none;
      box-shadow: none;
    }
    .IconNormal {
      display: none;
    }
    .IconHover {
      display: block;
      height: 30px;
      width: 30px;
      background: none;
      box-shadow: none;
    }
    .Hover {
      display: block;
      position: relative;
      // right: -1%;
      background: none;
      box-shadow: none;
    }
    .Normal {
      display: none;
    }
  }
`
export const ColumnRight = styled.div`
  width: 400px;
  height: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000000;
  justify-content: center;
  gap: 30px;
  padding: 0px 175px;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 6px #9ca3af;
  .text1 {
    font-size: 26px;
    font-weight: bold;
    @media (max-width: 575px) {
      font-size: 26px;
    }
  }
  @media (max-width: 575px) {
    width: 100%;
    padding: 0px;
    height: 150px;
    text-align: center;
    display: none;
  }
`
