'use client'

import { ModalContainer_2,Text} from "./styledModal"



const Modal_2 = ({onClose}) => {
    return (
        
        <ModalContainer_2 >
        <Text>The BRC-20 tokens was created in early March 2023 by Domo, an enigmatic blockchain analyst.
</Text>
        

        </ModalContainer_2>
        
    )
}
export default Modal_2