import styled from 'styled-components'

export const ModalContainer_1 = styled.div`
  width: 450px;
  height: 390px;
  display: flex;
  align-items: center;
  background: #000000;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 6px #ffffff47;
  color: white;
  padding: 0px 150px;
  line-height: 2;
  @media (max-width: 575px) {
    height: 200px;
    width: 87%;
    position: relative;
    font-size: 18px;
    top: 25px;
    padding: 0px;
    line-height: 1.5;
  }
`
export const Text = styled.div`
  padding: 0px 40px;
  font-size: 20px;
  @media (max-width: 575px) {
    padding: 15px 15px;
    font-size: 18px;
  }
`
export const ModalContainer_2 = styled.div`
  width: 450px;
  height: 390px;
  display: flex;
  align-items: center;
  background: #000000;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 6px #ffffff47;
  color: white;
  padding: 0px 150px;
  line-height: 2;
  @media (max-width: 575px) {
    height: 200px;
    width: 87%;
    position: relative;
    font-size: 18px;
    top: 25px;
    padding: 0px;
    line-height: 1.5;
  }
`
export const ModalContainer_3 = styled.div`
  width: 450px;
  height: 390px;
  display: flex;
  align-items: center;
  background: #000000;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 6px #ffffff47;
  color: white;
  padding: 0px 150px;
  line-height: 2;
  @media (max-width: 575px) {
    height: 200px;
    width: 87%;
    position: relative;
    font-size: 18px;
    top: 25px;
    padding: 0px;
    line-height: 1.5;
  }
`
export const ModalContainer_4 = styled.div`
  width: 450px;
  height: 390px;
  display: flex;
  align-items: center;
  background: #000000;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 6px #ffffff47;
  color: white;
  padding: 0px 150px;
  line-height: 2;
  @media (max-width: 575px) {
    height: 200px;
    width: 87%;
    position: relative;
    font-size: 18px;
    top: 25px;
    padding: 0px;
    line-height: 1.5;
  }
`
