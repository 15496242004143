'use client'

import images from '../configs/images'
import {
  Container,
  Column1,
  Text,
  Title,
  Wraper,
  IMG,
  Bottom,
  Column2,
  Column3,
  Input,
  Emaill,
} from './Component/styled/pageHome5'
import {useEffect, useState} from 'react'

const PageHome5: React.FC = () => {
  // const [Email, setEmail] = useState('')
  return (
    <Container>
      <Title style={{backgroundImage: `url("./images/HomeIcon/footer-bg1.png")`}}>
        {/* <img className="SatoshiNakamoto" src={images.SatoshiNakamoto} alt="SatoshiNakamoto" /> */}
      </Title>
      <Wraper>
        <img className="ImagesBody" src={images.IconHomePage5_2} alt="ImagesBody" />
        <div className="Mobile">
          <Column1>
            <Text>
              <img className="Buy5" src={images.Buy5} alt="Buy5" />
            </Text>
            <Text>UniSat</Text>
            <Text>Binance</Text>
            <Text>OKX</Text>
            <Text>Mexc</Text>
          </Column1>
          <Column2>
            <Text className="Text1">Language</Text>
            <Text>English</Text>
            <Text>Japanese</Text>
            <Text>Korean</Text>
            <Text>China</Text>
          </Column2>
        </div>
        <Column3>
          <Text className="Text1">Join with us</Text>
          {/* <Emaill>
            <Input
              placeholder="Your Email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
            />
            {Email.length > 0 && (
              <button className="Button1">
                <img src={images.IconHomePage5_6} className='abc' alt='abc'/>
              </button>
            )}
          </Emaill> */}
          <IMG>
            <a href="https://twitter.com/satsbrc" target="blank">
              <img className="twitter" src={images.IconHomePage5_4} alt="twitter" />
            </a>
            <a href="https://t.me/satsbrc_channel" target="blank">
              <img className="telegram" src={images.IconHomePage5_3} alt="telegram" />
            </a>
          </IMG>
        </Column3>
      </Wraper>
      <Bottom>
        <img
          className="ImagesBot"
          height={30}
          width={30}
          src={images.IconHomePage5_5}
          alt="ImagesBot"
        />
        <Text className="textBot">2023 satsbrc.vip. All Right Reserved.</Text>
      </Bottom>
    </Container>
  )
}
export default PageHome5
