import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  background: #13161f;
  padding-top: 100px;
`
export const Wraper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 100px;
  align-items: flex-start;
  border-bottom: 1px solid #4f5259;
  width: 100%;
  .Mobile {
    display: flex;
    flex-direction: row;
    gap: 80px;
  }
  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    // padding: 0px 0px 0px 20px;
    align-items: center;
    border-bottom: 1px solid #4f5259;
    .Mobile {
      display: flex;
      flex-direction: row;
      gap: 20px;
      width: 100%;
    }
  }
  .Text1 {
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 26px;
    font-weight: bold;
    @media (max-width: 575px) {
      font-size: 22px;
      gap: 5px;
    }
  }
  .Text3 {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    @media (max-width: 575px) {
      align-items: flex-start;
    }
  }
  .Text4 {
    @media (max-width: 575px) {
      display: flex;
      flex-direction: row;
    }
  }
  .Text2 {
    background: none;
    border: none;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: center;
    text-shadow: 2px 0 #ff9800, -2px 0 #ff9800, 0 2px #ff9800, 0 -2px #ff9800, 1px 1px #ff9800,
      -1px -1px #ff9800, 1px -1px #ff9800, -1px 1px #ff9800;
    color: black;
  }
  .ImagesBody {
    width: 280px;
    height: 280px;
    @media (max-width: 575px) {
      width: 80%;
      height: 80%;
    }
  }
`
export const Text = styled.div`
  font-size: 19px;
  color: #9ca3af;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
export const Title = styled.div`
  height: 200px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  background-position: center;
  background-size: cover;
  .SatoshiNakamoto {
    width: 300px;
    height: 80px;
  }
  @media (max-width: 575px) {
    font-size: 32px;
  }
  .Title {
    height: 32px;
    width: 350px;
    @media (max-width: 575px) {
      height: 54px;
    }
  }
`
export const Column1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 240px;
  gap: 30px;
  .Buy5 {
    width: 250px;
  }
  @media (max-width: 575px) {
    width: 70%;
    padding-left: 40px;
    .Buy5 {
      width: 100%;
    }
  }
`
export const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 150px;
  gap: 30px;
  :hover {
    color: white;
  }
  @media (max-width: 575px) {
    width: 50%;
    .Text1 {
      font-size: 20px;
    }
  }
`
export const Column3 = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  gap: 30px;
  align-items: flex-start;
  @media (max-width: 575px) {
    width: 100%;
    align-items: center;
  }
`
export const IMG = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  .twitter {
    height: 60px;
    width: 60px;
  }
  .telegram {
    height: 40px;
  }
`
export const Input = styled.input`
  width: 350px;
  height: 45px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  font-size: 22px;
`
export const Emaill = styled.div`
  display: flex;
  flex-direction: row;
  width: 350px;
  align-items: center;
  .Button1 {
    position: absolute;
    right: 430px;
  }
  @media (max-width: 575px) {
    align-items: center;
    position: relative;
    left: -10px;
    .Button1 {
      position: absolute;
      right: 20px;
    }
  }
`
export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  gap: 10px;
  .ImagesBot {
    @media (max-width: 575px) {
      width: 35px;
      height: 35px;
    }
  }
  .textBot {
    color: white;
  }
`
