import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {Link, Route, BrowserRouter as Router, Routes} from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import HomePage from './App'
import Market from './pages/market'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/market" element={<Market />} />
    </Routes>
  </Router>,
)
reportWebVitals()
