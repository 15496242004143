import {Link} from 'react-router-dom'
import images from '../configs/images'
import {
  Background,
  BodyContainer,
  ButtonNetwork,
  Connect,
  DivLeft,
  DivRight,
  IconCoins,
  IconContainer,
  IconNetwork,
  Market,
  Menu,
  Row1,
  Text,
  Text1,
  Text2,
  Title,
  Wraper,
} from './Component/styled/pageHome1'
import {useState} from 'react'
const HomePage1: React.FC = () => {
  const [IconCoin1, setIconcoin1] = useState(true)
  const [IconCoin2, setIconcoin2] = useState(true)
  const HandleDisable = () => {
    setIconcoin1(false)
    setIconcoin2(false)
    console.log(setIconcoin1)
  }
  const a =
    '{"p":"brc-20","op":"deploy","tick":"SN20","max":"21000000000000000","lim":"21000000000000000"}'
  return (
    <Background>
      <Menu>
        <Wraper>
          <img className="Logo" src={images.Logo} alt="Logo" />
          <img className="Logo-Mobile" src={images.LogoMobile} alt="Logo-Mobile" />
          <ButtonNetwork>
            <Market>
              <Link to={'/market'} target="blank">
                <img className="Market" src={images.ButtonMarket} alt="Market" />
              </Link>
            </Market>
            <Connect href="https://unisat.io/market/brc20?tick=sn20" target="blank">
              <img className="connect" src={images.ButtonConnect} alt="connect" />
            </Connect>
          </ButtonNetwork>
        </Wraper>
      </Menu>
      <Title>
        {/* <img className="Title" src={images.IconHome16} /> */}
        {/* <motion.div
          className="animation"
          animate={{x: [2000, -1000]}}
          transition={{repeat: Infinity, duration: 30, delay: 0}}
        >
        </motion.div> */}
        {/* <motion.div
          className="animation-Mobile"
          animate={{x: [300, -1000]}}
          transition={{repeat: Infinity, duration: 10, delay: 0}}
        >
          <img className="Title-Mobile" src={images.IconHome16} alt="Title-Mobile" />
        </motion.div> */}
        <div className="Title">{a}</div>
      </Title>
      <BodyContainer>
        <DivLeft>
          <Text1>
            <img className="TextImg_1" src={images.IconHomePage1_a} alt="TextImg_1" />
            <img
              className="TextImg_1_Mobile"
              src={images.TextImg_1_Mobile}
              alt="TextImg_1_Mobile"
            />
          </Text1>
          <IconNetwork>
            <a href="https://twitter.com/satsbrc" target="blank">
              <img className="tw" src={images.IconHome1} alt="tw" />
            </a>
            <a href="https://t.me/satsbrc_channel" target="blank">
              <img className="tele" src={images.IconHome2} alt="tele" />
            </a>
          </IconNetwork>
          <Text2>
            <img className="TextImg_2" src={images.IconHomePage1_b} alt="TextImg_2" />
          </Text2>
          <IconContainer>
            <IconCoins>
              <Row1
                className="Row1"
                aria-disabled={IconCoin1}
                onClick={HandleDisable}
                style={{background: '#252433'}}
                href="https://unisat.io/market/brc20?tick=sn20"
                target="blank"
              >
                <Text style={{color: 'white'}}>UniSat</Text>
                <img className="IconCoin" src={images.IconHome4} alt="IconCoin" />
              </Row1>
              <Row1 aria-disabled={IconCoin2} onClick={HandleDisable}>
                <Text>Binance</Text>
                <img className="IconCoin" src={images.IconHome5} alt="IconCoin" />
              </Row1>
              <Row1>
                <Text>Poloniex</Text>
                <img className="IconCoin" src={images.IconHome6} alt="IconCoin" />
              </Row1>
              <Row1>
                <Text>BitMart</Text>
                <img className="IconCoin" src={images.IconHome7} alt="IconCoin" />
              </Row1>
              <Row1>
                <Text>BingX</Text>
                <img className="IconCoin" src={images.IconHome8} alt="IconCoin" />
              </Row1>
              <Row1>
                <Text>AscendEX</Text>
                <img className="IconCoin" src={images.IconHome9} alt="IconCoin" />
              </Row1>
            </IconCoins>
            <IconCoins>
              <Row1>
                <Text>MEXC</Text>
                <img className="IconCoin" src={images.IconHome10} alt="IconCoin" />
              </Row1>
              <Row1>
                <Text>OKX</Text>
                <img className="IconCoin" src={images.IconHome11} alt="IconCoin" />
              </Row1>
              <Row1>
                <Text>BITGET</Text>
                <img className="IconCoin" src={images.IconHome12} alt="IconCoin" />
              </Row1>
              <Row1>
                <Text>KUCOIN</Text>
                <img className="IconCoin" src={images.IconHome13} alt="IconCoin" />
              </Row1>
              <Row1>
                <Text>GATE.IO</Text>
                <img className="IconCoin" src={images.IconHome14} alt="IconCoin" />
              </Row1>
              <Row1>
                <Text>Lbank</Text>
                <img className="IconCoin" src={images.IconHome15} alt="IconCoin" />
              </Row1>
            </IconCoins>
          </IconContainer>
        </DivLeft>
        <DivRight>
          <img className="Images" src={images.IconHome3} alt="Images" />
        </DivRight>
      </BodyContainer>
    </Background>
  )
}
export default HomePage1
