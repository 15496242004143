import styled from 'styled-components'

export const Background = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media (max-width: 575px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`
export const Menu = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width:575px){
    height:100%;
    // padding: 0px 50px 0px 0px;
  }
  .Logo-Mobile{
    display:none;
    @media (max-width: 575px) {
      width: 100px;
      height: 100px;
      display: block;
      position: relative;
  }
  }
  .Logo {
    width: 300px;
    @media (max-width: 575px) {
      width: 550px;
      height: 100px;
      display: none;
  }
`
export const Wraper = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 50px 0px 50px;
  @media (max-width: 575px) {
    padding: 0px;
  }
`
export const ButtonNetwork = styled.div`
  width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
`
export const Market = styled.div`
  width: 160px;
  height: 40px;
  .Market {
    width: 160px;
    height: 40px;
  }
  @media (max-width: 575px) {
    width: 120px;
    height: 35px;
    .Market {
      width: 100%;
      height: 35px;
    }
  }
`

export const Connect = styled.a`
  width: 160px;
  height: 40px;
  .connect {
    width: 160px;
    height: 44px;
  }
  @media (max-width: 575px) {
    width: 120px;
    height: 35px;
    .connect {
      width: 100%;
      height: 100%;
    }
  }
`
export const Title = styled.div`
  border-radius: 20px;
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  height: 50px;
  white-space: nowrap;
  overflow: hidden;
  background: #26262f;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffa800;
  @media (max-width: 575px) {
    height: 35px;
    border-radius: 0px;
    .Title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 7px;
    }
  }
`
export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 20px;
  }
`
export const Text = styled.div`
  color: #9e9e9e;
  font-size: 32px;
  :hover {
    color: white;
  }
  @media (max-width: 575px) {
    font-size: 32px;
  }
`
export const Text1 = styled.div`
  width: 650px;
  height: 22px;
  .TextImg_1 {
    width: 100%;
    height: 100%;
  }
  .TextImg_1_Mobile {
    display: none;
  }
  @media (max-width: 575px) {
    font-size: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .TextImg_1 {
      display: none;
    }
    .TextImg_1_Mobile {
      display: block;
      width: 90%;
    }
  }
`
export const Text2 = styled.div`
  width: 400px;
  height: 22px;
  .TextImg_2 {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 575px) {
    font-size: 16px;
    font-weight: 900;
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .TextImg_2 {
      width: 70%;
      height: 80%;
    }
  }
`
export const IconNetwork = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  .tw {
    width: 70px;
    @media (max-width: 575px) {
      height: 50px;
      width: 50px;
    }
  }
  .tele {
    width: 70px;
    @media (max-width: 575px) {
      height: 50px;
      width: 50px;
    }
  }
`
export const IconCoins = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 300px;
  :hover {
    background: #252433;
    color: white;
  }
  .IconCoin {
    @media (max-width: 575px) {
      height: 50px;
    }
  }
  @media (max-width: 575px) {
    width: 100%;
  }
`
export const Row1 = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #1d1b20;
  height: 70px;
  border-radius: 8px;
  padding: 0px 15px 0px 20px;
  text-decoration: none;
  @media (max-width: 575px) {
    width: 89%;
    padding: 0px 15px 0px 20px;
  }
`
export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  justify-content: center;
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
`
export const DivLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  .ColumnPC {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 22px;
    gap: 5px;
    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .ColumnPC_Mobile {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 22px;
      gap: 5px;
      @media (max-width: 575px) {
        flex-direction: row;
      }
    }
  }
  .Text2 {
    font-size: 22px;
    font-weight: 900;
    color: white;
    @media (max-width: 575px) {
      font-size: 16px;
      padding-left: 10px;
    }
  }
`
export const DivRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .Images {
    @media (max-width: 575px) {
      width: 70%;
    }
  }
`
