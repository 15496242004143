'use client'
import { ModalContainer_1,Text } from "./styledModal"


const Modal_1 = ({onClose,}) => {
    return (
        
        <ModalContainer_1 >
            {/* <button className="Close" onClick={onClose}>
            <img className="ImageClose" src={images.IconCancel} alt="ImageClose" />
            </button> */}
        <Text>The BRC-20 token is an experimental fungible token standard specifically designed for the Bitcoin blockchain.
             It allows the creation and transfer of fungible tokens through ordinal protocol.
        </Text>

        </ModalContainer_1>
    )
}
export default Modal_1